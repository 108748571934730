import "./About.scss";

function About() {
  return (
    <section className="about" id="about">
      <h3 className="about__header">About Me</h3>
      <p className="about__paragraph">
        I'm a Software Developer specializing in front-end development, where I
        bring ideas and designs to life through responsive and accessible
        applications. With a background in consumer behavior, I'm passionate
        about using technology to address real-world challenges and create
        practical, user-friendly solutions. My enthusiasm for tech drives my
        dedication to continuous learning and growth.
      </p>
      <div className="about__skills-container">
        <div className="about__skills">
          <h4 className="about__subheader">Development</h4>
          <ul>
            <li>ReactJS</li>
            <li>JavaScript</li>
            <li>HTML & CSS</li>
            <li>NodeJS</li>
          </ul>
        </div>
        <div className="about__skills">
          <h4 className="about__subheader">UX/UI</h4>
          <ul>
            <li>Figma</li>
            <li>Mockups/Prototypes</li>
            <li>Adobe Photoshop</li>
          </ul>
        </div>
        <div className="about__skills">
          <h4 className="about__subheader">Other</h4>
          <ul>
            <li>Agile Methodologies</li>
            <li>Git/GitHub</li>
            <li>Collaboration</li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default About;
