import "../ProjectPages.scss";
import { useEffect } from "react";
import Projects from "../../components/Projects/Projects";
import spotifyPlayerHeader from "../../assets/images/spotify.svg";
import linkIcon from "../../assets/icons/link.svg";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

function SpotifyPlayer() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <div className="project">
        <h1 className="project__header">TrackSpin</h1>
        <div className="project__content">
          <div className="project__section">
            <h3 className="project__subheader">Project Overview</h3>
            <p className="project__overview">
              TrackSpin is a music playback application developed with the
              primary goal of exploring and implementing various authentication
              mechanisms provided by the Spotify API. This application allows
              users to securely access their personal music data, including
              their liked songs, directly within the app. The focus was on
              creating a user-friendly interface that is both simple and
              intuitive, while ensuring the secure authentication of users.
            </p>
          </div>
          <div className="project__section">
            <h3 className="project__subheader">Tech and Libraries</h3>
            <div className="project__tech">
              <p>
                Front-end development for the TrackSpin project included
                ReactJS, BEM and SCSS for styling, Axios for communication with
                the Spotify API, and GitHub for version control and project
                management.
              </p>
              <a
                href="https://trackspin.vercel.app"
                target="_blank"
                rel="noreferrer"
                className="project__link"
              >
                trackspin.vercel.app
                <img
                  src={linkIcon}
                  alt="link icon"
                  className="project__link-icon"
                />
              </a>
            </div>
          </div>
        </div>
        <img
          src={spotifyPlayerHeader}
          alt="spotify cover"
          className="project__cover"
        />
      </div>
      <Projects currentProject="/trackspin" isProjectPage={true} />
      <Footer />
    </>
  );
}

export default SpotifyPlayer;
