import "../ProjectPages.scss";
import { useEffect } from "react";
import Projects from "../../components/Projects/Projects";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import lushBasketHeader from "../../assets/images/lush.svg";
import lushBasketMockup from "../../assets/images/lush1.jpg";

function LushBasket() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <div className="project">
        <h1 className="project__header">LUSH Basket</h1>
        <div className="project__content">
          <div className="project__section">
            <h3 className="project__subheader">Project Overview</h3>
            <p className="project__overview">
              The LUSH Basket POS system is designed to streamline the retail
              shopping experience, utilizing automation technology to
              synchronize digital shopping carts with physical item selection.
              Our objective was to create an intuitive platform
              that addresses common retail challenges, significantly improving
              customer satisfaction. The platform includes features like mobile
              self-checkout and in-app support, making shopping seamless and
              efficient through an advanced point-of-sale (POS) system.
            </p>
          </div>
          <div className="project__section">
            <h3 className="project__subheader">Tech and Libraries</h3>
            <p className="project__tech">
              Front-end development for the LUSH basket project utilizes ReactJS
              with react-router-dom for navigation, BEM and SCSS for styling,
              and NodeJS with ExpressJS for the back-end. MySQL is used for
              database management, Axios for making HTTP requests, and GitHub
              for version control and collaboration.
            </p>
          </div>
        </div>
        <img
          src={lushBasketHeader}
          alt="lush cover"
          className="project__cover"
        />
        <img src={lushBasketMockup} alt="lush mockup" className="project__mockup"/>
      </div>
      <Projects currentProject="/lush-basket" isProjectPage={true} />
      <Footer />
    </>
  );
}

export default LushBasket;
