import "./Projects.scss";
import { Link } from "react-router-dom";
import spotifyPlayerCover from "../../assets/images/spotify.svg";
import wxCover from "../../assets/images/wx.svg";
import inStockCover from "../../assets/images/instock.svg";
import lushBasketCover from "../../assets/images/lush.svg";

const Projects = ({ currentProject, isProjectPage }) => {
  const projects = [
    {
      path: "/trackspin",
      image: spotifyPlayerCover,
      name: "TrackSpin",
      type: "Music player powered by the Spotify API",
    },
    {
      path: "/lush-basket",
      image: lushBasketCover,
      name: "LUSH Basket",
      type: "A POS system that enhances retail shopping",
    },
    {
      path: "/in-stock",
      image: inStockCover,
      name: "InStock",
      type: "Inventory management system",
    },
    {
      path: "/weather",
      image: wxCover,
      name: "WX",
      type: "Weather app powered by the OpenWeather API",
    }
  ];

  // Filter out the current project from the list if it's a project page
  let filteredProjects = isProjectPage ? projects.filter((project) => project.path !== currentProject) : projects;

  if (isProjectPage && filteredProjects.length > 2) {
    filteredProjects = filteredProjects.slice(0, 2);
  }

  return (
    <div>
      <section className={`projects ${isProjectPage ? "project--page" : "" }`}>
        <div className={`projects__container ${isProjectPage ? "projects__container--page" : "" }`}>
        {isProjectPage && <h3 className="projects__header">More Projects</h3>}
        <ul className="projects__list">
          {filteredProjects.map((project) => (
            <li key={project.path} className="projects__item">
              <Link to={project.path}>
                <div className="projects__image-container">
                <img className="projects__image" src={project.image} alt={`${project.name} cover`}/>
                </div>
                <div className="projects__details">
                  <p className={`projects__name ${isProjectPage ? 'projects__name--page' : "" }`}>{project.name}</p>
                  <p className={`projects__type ${isProjectPage ? 'projects__type--page' : "" }`}>{project.type}</p>
                </div>
              </Link>
            </li>
          ))}
        </ul>
        </div>
      </section>
    </div>
  );
};

export default Projects;
