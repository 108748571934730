import "../ProjectPages.scss";
import { useEffect } from "react";
import weatherHeader from "../../assets/images/wx.svg";
import linkIcon from "../../assets/icons/link.svg";
import Projects from "../../components/Projects/Projects";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

function Weather() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <div className="project">
        <h1 className="project__header">WX</h1>
        <div className="project__content">
          <div className="project__section">
            <h3 className="project__subheader">Project Overview</h3>
            <p className="project__overview">
              WX is a mobile-focused weather application developed to provide
              users with detailed insights into weather conditions. The
              application features current weather updates, hourly forecasts,
              and daily predictions. WX utilizes colour schemes to represent
              varying weather conditions, enhancing user experience by
              intuitively conveying the weather through colour.
            </p>
          </div>
          <div className="project__section">
            <h3 className="project__subheader">Tech and Libraries</h3>
            <div className="project__tech">
              <p>
                Front-end development for the WX project utilizes ReactJS, with
                BEM and SCSS for advanced styling. Axios handles HTTP requests
                to the OpenWeather API, ensuring fast and accurate weather data
                retrieval.
              </p>
              {/* <a href="https://wx-amandafuhrer.vercel.app" target="_blank" rel="noreferrer"  className="project__link">
                wx-amandafuhrer.vercel.app
                <img src={linkIcon} alt="link icon" className="project__link-icon"/>
              </a> */}
            </div>
          </div>
        </div>
        <img src={weatherHeader} alt="weather cover" className="project__cover"/>
      </div>
      <Projects currentProject="/lush-basket" isProjectPage={true} />
      <Footer />
    </>
  );
}

export default Weather;
