import { useState } from "react";
import "./ContactForm.scss";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      access_key: "7991a082-833f-44b9-bf96-b8f2739d31bf",
      name,
      email,
      message,
    };

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();

      if (result.success) {
        toast.success("Thank you for your message! We'll be in touch soon.");
        // Clearing the form fields
        setName("");
        setEmail("");
        setMessage("");
      } else {
        toast.error(
          "Oops! Something went wrong with your submission. Please try again."
        );
      }
    } catch (error) {
      toast.error(
        "Failed to submit the form. Please check your internet connection and try again."
      );
    }
  };

  return (
    <div className="contact-form">
      <h1 className="contact-form__header">Get In Touch</h1>
      <form onSubmit={handleSubmit} className="contact-form__form">
        <div className="contact-form__group--half">
          <div className="contact-form__group">
            <label htmlFor="name" className="contact-form__label">
              name
            </label>
            <input
              id="name"
              type="text"
              value={name}
              onChange={(event) => setName(event.target.value)}
              placeholder="Enter your name"
              className="contact-form__input"
            />
          </div>
          <div className="contact-form__group">
            <label htmlFor="email" className="contact-form__label">
              email address
            </label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              placeholder="Enter your email"
              className="contact-form__input"
              required
            />
          </div>
        </div>
        <div className="contact-form__group">
          <label htmlFor="message" className="contact-form__label">
            message
          </label>
          <textarea
            id="message"
            value={message}
            onChange={(event) => setMessage(event.target.value)}
            placeholder="Enter your message"
            className="contact-form__textarea"
          />
        </div>
        <button type="submit" className="contact-form__button">
          submit
        </button>
      </form>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnHover
      />
    </div>
  );
};

export default ContactForm;
