import "./Header.scss";
import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import logo from "../../assets/icons/logo.svg";

function Header() {
  const [showHamburgerNavigation, setShowHamburgerNavigation] = useState(false);
  const location = useLocation();
  const projectPages = ['/spotify', '/weather', '/in-stock', '/lush-basket'];
  const onProjectPage = projectPages.includes(location.pathname);

  const handleShowNavigation = () => {
    setShowHamburgerNavigation(!showHamburgerNavigation);
  };

  const handleCloseNavigation = () => {
    setShowHamburgerNavigation(false);
  };

  const handleResize = () => {
    setShowHamburgerNavigation(false);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <header className="header">
      <Link to="/">
        <img src={logo} alt="logo" className="header__logo" />
      </Link>
      {/* Hamburger navigation */}
      <div
        className={`header__hamburger-navigation ${showHamburgerNavigation && "active"}`} onClick={handleShowNavigation}>
        <div className="bar1" />
        <div className="bar2" />
        <div className="bar3" />
      </div>
      <nav className={`header__navigation ${showHamburgerNavigation && "active"}`}>
      <ul>
          {onProjectPage && (
            <li className="header__navigation-item">
              <Link to="/" onClick={handleCloseNavigation}>home</Link>
            </li>
          )}
          {!onProjectPage && (
            <>
              <li className="header__navigation-item">
                <a href="#about" onClick={handleCloseNavigation}>about</a>
              </li>
              <li className="header__navigation-item">
                <a href="#connect" onClick={handleCloseNavigation}>connect</a>
              </li>
            </>
          )}
        </ul>
      </nav>
    </header>
  );
}

export default Header;
