import "./Hero.scss";

function Hero() {
  return (
    <section className="hero">
      <h1 className="hero__title">Amanda Fuhrer</h1>
      <h2 className="hero__subtitle">
        Software Developer and UI Designer based in Toronto, ON
      </h2>
    </section>
  );
}

export default Hero;
