import "../ProjectPages.scss";
import { useEffect } from "react";
import Projects from "../../components/Projects/Projects";
import inStockHeader from "../../assets/images/instock.svg";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import inStockMockupHome from "../../assets/images/instock1.svg";
import inStockMockupModal from "../../assets/images/instock2.svg";
import inStockMockupEdit from "../../assets/images/instock3.svg";

function InStock() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <div className="project">
        <h1 className="project__header">InStock</h1>
        <div className="project__content">
          <div className="project__section">
            <h3 className="project__subheader">Project Overview</h3>
            <p className="project__overview">
              The InStock Inventory Management System is a collaborative effort
              designed to enhance the efficiency of managing inventory and
              warehouse operations. Our goal was to develop a responsive and
              user-friendly platform that simplifies the complexities associated
              with inventory management. It enables seamless interactions for
              users, offering capabilities to efficiently manage inventory items
              and warehouse details with ease.
            </p>
          </div>
          <div className="project__section">
            <h3 className="project__subheader">Tech and Libraries</h3>
            <p className="project__tech">
              Front-end development for the InStock project is powered by ReactJS
              with react-router-dom for navigation, and BEM with SCSS for
              styling. The back-end utilizes ExpressJS and NodeJS for server
              operations, while MySQL manages the database, ensuring efficient
              data processing for inventory and warehouses.
            </p>
          </div>
        </div>
        <img
          src={inStockHeader}
          alt="in-stock cover"
          className="project__cover"
        />
        <img src={inStockMockupHome} alt="instock mockup home" className="project__mockup" />
        <img src={inStockMockupModal} alt="instock mockup modal" className="project__mockup" />
        <img src={inStockMockupEdit} alt="instock mockup edit" className="project__mockup" />
      </div>
      <Projects currentProject="/in-stock" isProjectPage={true} />
      <Footer />
    </>
  );
}

export default InStock;
