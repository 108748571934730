import "./App.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./pages/Home/Home";
import SpotifyPlayer from "./pages/SpotifyPlayer/SpotifyPlayer";
import Weather from "./pages/Weather/Weather";
import InStock from "./pages/InStock/InStock";
import LushBasket from "./pages/LushBasket/LushBasket";

function App() {
  return (
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/trackspin" element={<SpotifyPlayer />} />
          <Route path="/weather" element={<Weather />} />
          <Route path="/in-stock" element={<InStock />} />
          <Route path="/lush-basket" element={<LushBasket />} />
        </Routes>
    </BrowserRouter>
  );
}

export default App;
