import "./Footer.scss";
import logo from "../../assets/icons/logo.svg";

function Footer() {

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  }

  return (
    <footer className="footer">
      <button onClick={handleScrollToTop}>
        <img src={logo} alt="logo" className="footer__logo" />
      </button>
      <div className="footer__socials">
      <a
          href="https://www.linkedin.com/in/amanda-fuhrer"
          target="_blank"
          rel="noreferrer"
          className="footer__socials--item"
        >
          linkedin
        </a>
        <a
          href="https://github.com/amanda-fuhrer"
          target="_blank"
          rel="noreferrer"
          className="footer__socials--item"
        >
          github
        </a>
        <a
          href="mailto:amandafuhrer@gmail.com"
          className="footer__socials--item"
        >
          email
        </a>
      </div>
    </footer>
  );
}

export default Footer;
