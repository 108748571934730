import "./Home.scss"
import Hero from "../../components/Hero/Hero";
import Header from "../../components/Header/Header"
import Projects from "../../components/Projects/Projects";
import Footer from "../../components/Footer/Footer";
import About from "../../components/About/About";
import ContactForm from "../../components/ContactForm/ContactForm";

function Home() {
  return (
    <section className="home">
      <Header />
      <Hero />
        <section id="projects">
          <Projects />
        </section>
        <section id="about">
          <About />
        </section>
        <section id="connect">
          <ContactForm />
        </section>
        <Footer />
    </section>
  );
}

export default Home;
